@import url("https://fonts.googleapis.com/css2?family=Inter:wght@400;700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Open+Sans:wght@400;700&display=swap");
@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  .primary-font {
    @apply font-primary;
  }

  .secondary-font {
    @apply font-secondary;
  }

  .flex-col-left-top {
    @apply flex flex-col justify-start items-start gap-1 sm:gap-2;
  }

  .flex-col-left-center {
    @apply flex flex-col justify-center items-start gap-1 sm:gap-2;
  }

  .flex-col-left-bottom {
    @apply flex flex-col justify-end items-start gap-1 sm:gap-2;
  }

  .flex-col-center-top {
    @apply flex flex-col justify-start items-center gap-1 sm:gap-2;
  }

  .flex-col-center-center {
    @apply flex flex-col justify-center items-center gap-1 sm:gap-2;
  }

  .flex-col-center-bottom {
    @apply flex flex-col justify-end items-center gap-1 sm:gap-2;
  }

  .flex-col-right-top {
    @apply flex flex-col justify-start items-end gap-1 sm:gap-2;
  }

  .flex-col-right-center {
    @apply flex flex-col justify-center items-end gap-1 sm:gap-2;
  }

  .flex-col-right-bottom {
    @apply flex flex-col justify-end items-end gap-1 sm:gap-2;
  }

  .flex-row-left-top {
    @apply flex flex-row justify-start items-start gap-1 sm:gap-2;
  }

  .flex-row-left-center {
    @apply flex flex-row justify-start items-center gap-1 sm:gap-2;
  }

  .flex-row-left-bottom {
    @apply flex flex-row justify-start items-end gap-1 sm:gap-2;
  }

  .flex-row-center-top {
    @apply flex flex-row justify-center items-start gap-1 sm:gap-2;
  }

  .flex-row-center-center {
    @apply flex flex-row justify-center items-center gap-1 sm:gap-2;
  }

  .flex-row-center-bottom {
    @apply flex flex-row justify-center items-end gap-1 sm:gap-2;
  }

  .flex-row-right-top {
    @apply flex flex-row justify-end items-start gap-1 sm:gap-2;
  }

  .flex-row-right-center {
    @apply flex flex-row justify-end items-center gap-1 sm:gap-2;
  }

  .flex-row-right-bottom {
    @apply flex flex-row justify-end items-end gap-1 sm:gap-2;
  }

  .gap-lg {
    @apply gap-16;
  }

  .gap-md {
    @apply gap-8;
  }

  .gap {
    @apply gap-4;
  }

  .gap-sm {
    @apply gap-1;
  }

  .gap-xs {
    @apply gap-0.5;
  }

  .full {
    @apply w-full;
  }

  .one-half {
    @apply w-1/2;
  }

  .one-third {
    @apply w-1/3;
  }

  .two-thirds {
    @apply w-2/3;
  }

  .padding-lg {
    @apply p-16;
  }

  .padding {
    @apply p-4;
  }

  .padding-sm {
    @apply p-2;
  }

  h1 {
    @apply text-xl sm:text-2xl primary-font font-bold text-white;
  }

  h2 {
    @apply text-lg sm:text-xl primary-font text-white;
  }

  h3 {
    @apply text-base sm:text-lg primary-font text-white;
  }

  h4 {
    @apply text-sm sm:text-base primary-font text-white;
  }

  h5 {
    @apply text-xs sm:text-sm primary-font text-white;
  }

  h6 {
    @apply text-xs primary-font text-white;
  }

  p {
    @apply text-sm sm:text-base secondary-font text-white;
  }

  small {
    @apply text-xs secondary-font text-white;
  }

  label,
  span,
  a,
  button,
  input,
  textarea {
    @apply secondary-font;
  }
}

@layer components {
  .page {
    @apply flex flex-col sm:flex-row justify-center items-start;
  }

  .container {
    @apply flex flex-col justify-center items-center w-full;
  }

  .content {
    @apply flex flex-col justify-start items-start gap-6 w-full p-6;
  }

  .card {
    @apply flex flex-col gap-2 p-4 rounded-md bg-[#3A5936];
  }

  .card-product-image {
    @apply rounded-md h-48 mx-auto;
  }

  .review-product-image {
    @apply rounded-md h-48 mx-auto;
  }

  .button-primary {
    @apply flex items-center justify-center gap-1 p-2 rounded-md bg-[#9DF15B] text-black text-sm sm:text-base font-bold;
  }

  .button-secondary {
    @apply flex items-center justify-center gap-1 p-2 rounded-md bg-transparent text-white text-sm sm:text-base font-bold border border-white;
  }

  .button-ternary {
    @apply flex items-center justify-center gap-1 p-2 rounded-md bg-[#fff] text-black text-sm sm:text-base font-bold;
  }

  .button-cancel {
    @apply flex items-center justify-center gap-1 p-2 rounded-md bg-red-500 text-black text-sm sm:text-base font-bold;
  }

  .highlight {
    @apply text-[#9DF15B];
  }
}

/* Loader */
.spinner {
  animation: rotator 1.4s linear infinite;
}

@keyframes rotator {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(270deg);
  }
}

.path {
  stroke-dasharray: 187;
  stroke-dashoffset: 0;
  transform-origin: center;
  animation: dash 1.4s ease-in-out infinite, colors 5.6s ease-in-out infinite;
}

@keyframes dash {
  0% {
    stroke-dashoffset: 187;
  }

  50% {
    stroke-dashoffset: 46.75;
    transform: rotate(135deg);
  }

  100% {
    stroke-dashoffset: 187;
    transform: rotate(450deg);
  }
}

@keyframes colors {
  0% {
    stroke: #4285f4;
  }

  25% {
    stroke: #de3e35;
  }

  50% {
    stroke: #f7c223;
  }

  75% {
    stroke: #1b9a59;
  }

  100% {
    stroke: #4285f4;
  }
}
